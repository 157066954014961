.s-product {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;

  &__header {
    padding: 30px 30px 0 30px;
    display: flex;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {
      flex-direction: column;
    }

    @include media("<=576px") {
      padding: 0 30px 0 30px;
    }
  }
  &__body {
    padding: 0 30px;
  }
  &__footer {}

  &__media {
    margin-right: 30px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 50%;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      margin-right: 0;
      width: 100%;
    }
  }
  &__info-main {
    margin-left: 30px;
    width: 50%;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      margin-left: 0;
      width: 100%;
    }
  }

  &__brand {
    @include fontSize(16);
    @include lineHeight(24, 16);
    font-style: normal;
    font-weight: bold;
    text-transform: uppercase;
    color: $masterBlack200;
  }
  &__name {
    @include fontSize(32);
    @include lineHeight(48, 32);
    font-style: normal;
    font-weight: bold;
    color: $masterBlack500;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {
      @include fontSize(32);
      @include lineHeight(32, 32);
    }

    @include media("<=576px") {}
  }
  &__paragraph {
    @include fontSize(16);
    @include lineHeight(24, 16);
    font-style: normal;
    font-weight: normal;
    color: $masterBlack300;
  }
  &__attributes-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  &__attribute-item {
    margin: 0 10px 10px 0;
    padding: 5px 10px;
    background-color: $masterRed500;
    color: #fff;
    border-radius: 3px;
    font-size: 0.8em;
  }
  &__documents-wrapper {
    padding: 15px 0;
    display: flex;
    flex-direction: column;
  }
  &__featured-image {
    width: 100%;
  }

  &__tabs-wrapper {

  }

  .state{
    position: absolute;
    left: -10000px;
  }

  .flex-tabs{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .tab{
      //max-height: 40px;
    }

    .panel {
      padding: 15px 0;
      display: none;
      width: 100%;
      flex-basis: auto;
      border-top: 1px solid #eee;

      // from 992px to 1180
      @include media(">992px", "<=1180px") {

      }

      // from 769px to 992px
      @include media(">769px", "<=992px") {

      }

      // from 481px to 768px
      @include media(">481px", "<=768px") {

      }

      // from 320px to 480px
      @include media(">=320px", "<=480px") {
      }

      h3 {
        margin: 15px 0 0 0;
        padding: 0;
        @include fontSize(26);
        @include lineHeight(26, 26);
      }

      p {
        @include fontSize(16);
        @include lineHeight(24, 16);
        font-style: normal;
        font-weight: normal;
        color: #6D6E71;
      }
    }
  }

  .tab {
    display: inline-block;
    padding: 15px 30px;
    @include fontSize(21);
    @include lineHeight(26, 21);
    font-weight: bold;
    color: #C9C9C9;
    cursor: pointer;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      padding: 15px 0;
      @include fontSize(21);
      @include lineHeight(26, 21);
      color: $masterBlack500;
    }

    &:hover{
      color: $masterRed500;
    }
  }

  #tab-1:checked ~ .tabs #tab-1-label,
  #tab-2:checked ~ .tabs #tab-2-label,
  #tab-3:checked ~ .tabs #tab-3-label,
  #tab-4:checked ~ .tabs #tab-4-label,
  #tab-5:checked ~ .tabs #tab-5-label,
  #tab-6:checked ~ .tabs #tab-6-label,
  #tab-7:checked ~ .tabs #tab-7-label,
  #tab-8:checked ~ .tabs #tab-8-label {
    color: $masterRed500;
    cursor: default;

    &:hover{
      color: $masterRed500;
    }
  }

  #tab-1:checked ~ .tabs #tab-1-panel,
  #tab-2:checked ~ .tabs #tab-2-panel,
  #tab-3:checked ~ .tabs #tab-3-panel,
  #tab-4:checked ~ .tabs #tab-4-panel,
  #tab-5:checked ~ .tabs #tab-5-panel,
  #tab-6:checked ~ .tabs #tab-6-panel,
  #tab-7:checked ~ .tabs #tab-7-panel,
  #tab-8:checked ~ .tabs #tab-8panel{
    display: block;
  }

  @media (max-width: 600px){
    .flex-tabs{
      flex-direction: column;

      .tab{
        border-bottom: 1px solid #ccc;

        &:last-of-type{
          border-bottom: none;
        }
      }

      #tab-1-label{order:1;}
      #tab-2-label{order: 3;}
      #tab-3-panel{order: 2;}
      #tab-4-panel{order: 4;}
      #tab-5-panel{order: 5;}
      #tab-6-panel{order: 6;}
      #tab-7-panel{order: 7;}
      #tab-8-panel{order: 8;}
    }

    #tab-1:checked ~ .tabs #tab-1-label,
    #tab-1:checked ~ .tabs #tab-2-label,
    #tab-3:checked ~ .tabs #tab-3-label,
    #tab-4:checked ~ .tabs #tab-4-label,
    #tab-5:checked ~ .tabs #tab-5-label,
    #tab-6:checked ~ .tabs #tab-6-label,
    #tab-7:checked ~ .tabs #tab-7-label,
    #tab-8:checked ~ .tabs #tab-8-label {
      border-bottom: none;
    }

    #tab-1:checked ~ .tabs #tab-1-panel,
    #tab-2:checked ~ .tabs #tab-2-panel,
    #tab-3:checked ~ .tabs #tab-3-panel,
    #tab-4:checked ~ .tabs #tab-4-panel,
    #tab-5:checked ~ .tabs #tab-5-panel ,
    #tab-6:checked ~ .tabs #tab-6-panel,
    #tab-7:checked ~ .tabs #tab-7-panel,
    #tab-8:checked ~ .tabs #tab-8-panel{
      border-bottom: 1px solid #ccc;
    }
  }
}

.price-wrapper {
  &__head {
    padding: 15px 0 15px 0;
    border-bottom: 1px solid #eee;
  }
  &__body {
    padding: 15px 0;
    display: flex;
    flex-direction: column;
  }
  &__footer {}

  &__special-price {
    @include fontSize(16);
    @include lineHeight(24, 16);
    font-style: normal;
    color: $masterRed500;
  }
  &__special-price-key {
    font-weight: bold;
  }
  &__special-price-value {
    font-weight: normal;
    text-decoration: line-through;
  }
  &__special-price-currency {}

  &__price {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
  }
  &__price--quantity-0 {
    margin-left: 0;
  }
  &__price-value {
    @include fontSize(36);
    @include lineHeight(54, 36);
    font-style: normal;
    font-weight: bold;
    color: $masterRed500;
  }
  &__price-value--old {
    @include fontSize(30);
    @include lineHeight(48, 30);
    font-style: normal;
    font-weight: bold;
    color: $masterRed500;
    text-decoration: line-through;
  }
  &__price-currency {
    @include fontSize(14);
    @include lineHeight(21, 14);
    font-style: normal;
    font-weight: bold;
    color: $masterBlack200;
  }
  &__quantity {
    margin-top: 20px;
    .s1 {
      @include fontSize(16);
      @include lineHeight(24, 16);
      font-style: normal;
      font-weight: normal;
      color: $masterBlack500;
    }
    .s2 {
      @include fontSize(16);
      @include lineHeight(24, 16);
      font-style: normal;
      font-weight: normal;
      color: $masterBlack300;
    }
  }
}
