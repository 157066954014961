.user-menu {
  display: flex;

  @include media("≥1400px") {}

  @include media("≥1200px") {}

  @include media("≥992px") {}

  @include media("≥768px") {}

  @include media("≥576px") {}

  @include media("<576px") {}

  &__item {
    font-style: normal;
    font-weight: bold;
    @include fontSize(14);
    @include lineHeight(21, 14);

    &:not(:last-child) {
      margin-right: 25px;
    }
  }
  &__link {
    display: flex;
    align-items: center;
    color: $masterBlack500;
    text-decoration: none;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: $masterRed500;

      .user-menu__badge {
        color: #fff;
        background-color: #000;
      }
    }
  }
  &__link-text {}
  &__link-icon {
    margin-left: 10px;
    position: relative;
    top: 2px;
  }
  &__badge {
    position: absolute;
    top: -9px;
    left: 9px;
    width: 18px;
    height: 18px;
    @include fontSize(10);
    @include lineHeight(12, 10);
    color: #fff;
    background-color: $masterRed500;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease-in-out;
  }

  &__item--login {
    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      display: none;
    }
  }

  &__item--cart {
    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {}

    .user-menu__link-text {

      @include media("≥1400px") {}

      @include media("≥1200px") {}

      @include media("≥992px") {}

      @include media("≥768px") {}

      @include media("≥576px") {}

      @include media("<576px") {
        display: none;
      }
    }
  }
}
