* {
  box-sizing: border-box;
}

body {
  font-family: $fontPoppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  border: 0;

}
:focus {
  outline: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

.mc-flex { display: flex; }
.mc-mw-1200 { max-width: 1200px; }
.mc-w-100 { width: 100%; }
.mc-my-auto { margin: 0 auto; }
